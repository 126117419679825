import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '~/.nuxt/layouts.error.6668f097.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_sentryserver_51deea95 from 'nuxt_plugin_sentryserver_51deea95' // Source: ./sentry.server.js (mode: 'server')
import nuxt_plugin_sentryclient_27b50de6 from 'nuxt_plugin_sentryclient_27b50de6' // Source: ./sentry.client.js (mode: 'client')
import nuxt_plugin_pluginsmeticulous214cb652_3a846008 from 'nuxt_plugin_pluginsmeticulous214cb652_3a846008' // Source: ./plugins.meticulous.214cb652.js (mode: 'all')
import nuxt_plugin_pluginsretain427cd066_263a2654 from 'nuxt_plugin_pluginsretain427cd066_263a2654' // Source: ./plugins.retain.427cd066.js (mode: 'all')
import nuxt_plugin_webfrontendmiddleware20de8e62_74110506 from 'nuxt_plugin_webfrontendmiddleware20de8e62_74110506' // Source: ./web-frontend.middleware.20de8e62.js (mode: 'all')
import nuxt_plugin_automationmiddleware468d0b5a_16fcc7d4 from 'nuxt_plugin_automationmiddleware468d0b5a_16fcc7d4' // Source: ./automation.middleware.468d0b5a.js (mode: 'all')
import nuxt_plugin_dashboardmiddleware70115a1d_bcb8fa26 from 'nuxt_plugin_dashboardmiddleware70115a1d_bcb8fa26' // Source: ./dashboard.middleware.70115a1d.js (mode: 'all')
import nuxt_plugin_pluginsglobal7488a4c4_2ec467ae from 'nuxt_plugin_pluginsglobal7488a4c4_2ec467ae' // Source: ./plugins.global.7488a4c4.js (mode: 'all')
import nuxt_plugin_router_8d970c04 from 'nuxt_plugin_router_8d970c04' // Source: ./router.js (mode: 'all')
import nuxt_plugin_buildermiddleware255c90b8_5c793760 from 'nuxt_plugin_buildermiddleware255c90b8_5c793760' // Source: ./builder.middleware.255c90b8.js (mode: 'all')
import nuxt_plugin_databasemiddlewarea05f2004_614d8b48 from 'nuxt_plugin_databasemiddlewarea05f2004_614d8b48' // Source: ./database.middleware.a05f2004.js (mode: 'all')
import nuxt_plugin_coremiddleware560ebda2_3446965e from 'nuxt_plugin_coremiddleware560ebda2_3446965e' // Source: ./core.middleware.560ebda2.js (mode: 'all')
import nuxt_plugin_pluginsvueDatepicker62cb7a72_1004f70d from 'nuxt_plugin_pluginsvueDatepicker62cb7a72_1004f70d' // Source: ./plugins.vueDatepicker.62cb7a72.js (mode: 'client')
import nuxt_plugin_pluginsvue2smoothscroll997f738c_37c58748 from 'nuxt_plugin_pluginsvue2smoothscroll997f738c_37c58748' // Source: ./plugins.vue2-smooth-scroll.997f738c.js (mode: 'all')
import nuxt_plugin_pluginsglobale993087c_3f21e530 from 'nuxt_plugin_pluginsglobale993087c_3f21e530' // Source: ./plugins.global.e993087c.js (mode: 'all')
import nuxt_plugin_pluginutils_5b7dde8a from 'nuxt_plugin_pluginutils_5b7dde8a' // Source: ./nuxt-i18n/plugin.utils.js (mode: 'all')
import nuxt_plugin_pluginrouting_2980f155 from 'nuxt_plugin_pluginrouting_2980f155' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_9196fb8c from 'nuxt_plugin_pluginmain_9196fb8c' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_cookieuniversalnuxt_5d7b6979 from 'nuxt_plugin_cookieuniversalnuxt_5d7b6979' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_coreplugin1596bee1_195bb8ab from 'nuxt_plugin_coreplugin1596bee1_195bb8ab' // Source: ./core.plugin.1596bee1.js (mode: 'all')
import nuxt_plugin_pluginsi18n92442a32_9386f104 from 'nuxt_plugin_pluginsi18n92442a32_9386f104' // Source: ./plugins.i18n.92442a32.js (mode: 'all')
import nuxt_plugin_pluginsclientHandler741d148a_53deaeae from 'nuxt_plugin_pluginsclientHandler741d148a_53deaeae' // Source: ./plugins.clientHandler.741d148a.js (mode: 'all')
import nuxt_plugin_pluginsrealTimeHandler6d8eddca_2071ffd1 from 'nuxt_plugin_pluginsrealTimeHandler6d8eddca_2071ffd1' // Source: ./plugins.realTimeHandler.6d8eddca.js (mode: 'all')
import nuxt_plugin_pluginshasFeature69d8fec9_eac4f2d2 from 'nuxt_plugin_pluginshasFeature69d8fec9_eac4f2d2' // Source: ./plugins.hasFeature.69d8fec9.js (mode: 'all')
import nuxt_plugin_pluginspermissionscfa18936_f5af86d0 from 'nuxt_plugin_pluginspermissionscfa18936_f5af86d0' // Source: ./plugins.permissions.cfa18936.js (mode: 'all')
import nuxt_plugin_pluginsfeatureFlags4bc98cf4_3aa51ddb from 'nuxt_plugin_pluginsfeatureFlags4bc98cf4_3aa51ddb' // Source: ./plugins.featureFlags.4bc98cf4.js (mode: 'all')
import nuxt_plugin_pluginspapa7e2162e3_a5e7851c from 'nuxt_plugin_pluginspapa7e2162e3_a5e7851c' // Source: ./plugins.papa.7e2162e3.js (mode: 'all')
import nuxt_plugin_pluginsensureRendere98fe85e_3ba02cd6 from 'nuxt_plugin_pluginsensureRendere98fe85e_3ba02cd6' // Source: ./plugins.ensureRender.e98fe85e.js (mode: 'all')
import nuxt_plugin_pluginsposthogda35486e_76a5190e from 'nuxt_plugin_pluginsposthogda35486e_76a5190e' // Source: ./plugins.posthog.da35486e.js (mode: 'all')
import nuxt_plugin_pluginsrouter7e227e5c_fa580ef4 from 'nuxt_plugin_pluginsrouter7e227e5c_fa580ef4' // Source: ./plugins.router.7e227e5c.js (mode: 'all')
import nuxt_plugin_pluginsversion323b68de_2855481c from 'nuxt_plugin_pluginsversion323b68de_2855481c' // Source: ./plugins.version.323b68de.js (mode: 'all')
import nuxt_plugin_pluginsrouteMounted7dacfd14_77153546 from 'nuxt_plugin_pluginsrouteMounted7dacfd14_77153546' // Source: ./plugins.routeMounted.7dacfd14.js (mode: 'all')
import nuxt_plugin_databaseplugin16300186_2f38d45e from 'nuxt_plugin_databaseplugin16300186_2f38d45e' // Source: ./database.plugin.16300186.js (mode: 'all')
import nuxt_plugin_integrationsplugin3a94913e_449f62b0 from 'nuxt_plugin_integrationsplugin3a94913e_449f62b0' // Source: ./integrations.plugin.3a94913e.js (mode: 'all')
import nuxt_plugin_builderplugin4ddb09e3_7bdbe47a from 'nuxt_plugin_builderplugin4ddb09e3_7bdbe47a' // Source: ./builder.plugin.4ddb09e3.js (mode: 'all')
import nuxt_plugin_dashboardplugin598e5e48_632390be from 'nuxt_plugin_dashboardplugin598e5e48_632390be' // Source: ./dashboard.plugin.598e5e48.js (mode: 'all')
import nuxt_plugin_automationplugine5deaece_91d132e8 from 'nuxt_plugin_automationplugine5deaece_91d132e8' // Source: ./automation.plugin.e5deaece.js (mode: 'all')
import nuxt_plugin_baserowpremiumplugina83aa5fe_21c7bb3e from 'nuxt_plugin_baserowpremiumplugina83aa5fe_21c7bb3e' // Source: ./baserow_premium.plugin.a83aa5fe.js (mode: 'all')
import nuxt_plugin_pluginslicense4d119530_b9e056fa from 'nuxt_plugin_pluginslicense4d119530_b9e056fa' // Source: ./plugins.license.4d119530.js (mode: 'all')
import nuxt_plugin_baserowenterpriseplugin6761bca9_293df3ae from 'nuxt_plugin_baserowenterpriseplugin6761bca9_293df3ae' // Source: ./baserow_enterprise.plugin.6761bca9.js (mode: 'all')
import nuxt_plugin_webfrontendplugin9cf14ee4_6df24c3a from 'nuxt_plugin_webfrontendplugin9cf14ee4_6df24c3a' // Source: ./web-frontend.plugin.9cf14ee4.js (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root ? this.$root.$options.$nuxt : null
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const store = createStore(ssrContext)
  const router = await createRouter(ssrContext, config, { store })

  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"title":"Baserow","titleTemplate":"%s | Baserow","meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"}],"link":[{"rel":"icon","type":"image\u002Fpng","href":"\u002Fimg\u002Ffavicon_16.png","sizes":"16x16","hid":true},{"rel":"icon","type":"image\u002Fpng","href":"\u002Fimg\u002Ffavicon_32.png","sizes":"32x32","hid":true},{"rel":"icon","type":"image\u002Fpng","href":"\u002Fimg\u002Ffavicon_48.png","sizes":"64x64","hid":true},{"rel":"icon","type":"image\u002Fpng","href":"\u002Fimg\u002Ffavicon_192.png","sizes":"192x192","hid":true}],"style":[],"script":[]},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    beforeSerializeFns: ssrContext ? ssrContext.beforeSerializeFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (process.server && typeof nuxt_plugin_sentryserver_51deea95 === 'function') {
    await nuxt_plugin_sentryserver_51deea95(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sentryclient_27b50de6 === 'function') {
    await nuxt_plugin_sentryclient_27b50de6(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginsmeticulous214cb652_3a846008 === 'function') {
    await nuxt_plugin_pluginsmeticulous214cb652_3a846008(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginsretain427cd066_263a2654 === 'function') {
    await nuxt_plugin_pluginsretain427cd066_263a2654(app.context, inject)
  }

  if (typeof nuxt_plugin_webfrontendmiddleware20de8e62_74110506 === 'function') {
    await nuxt_plugin_webfrontendmiddleware20de8e62_74110506(app.context, inject)
  }

  if (typeof nuxt_plugin_automationmiddleware468d0b5a_16fcc7d4 === 'function') {
    await nuxt_plugin_automationmiddleware468d0b5a_16fcc7d4(app.context, inject)
  }

  if (typeof nuxt_plugin_dashboardmiddleware70115a1d_bcb8fa26 === 'function') {
    await nuxt_plugin_dashboardmiddleware70115a1d_bcb8fa26(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginsglobal7488a4c4_2ec467ae === 'function') {
    await nuxt_plugin_pluginsglobal7488a4c4_2ec467ae(app.context, inject)
  }

  if (typeof nuxt_plugin_router_8d970c04 === 'function') {
    await nuxt_plugin_router_8d970c04(app.context, inject)
  }

  if (typeof nuxt_plugin_buildermiddleware255c90b8_5c793760 === 'function') {
    await nuxt_plugin_buildermiddleware255c90b8_5c793760(app.context, inject)
  }

  if (typeof nuxt_plugin_databasemiddlewarea05f2004_614d8b48 === 'function') {
    await nuxt_plugin_databasemiddlewarea05f2004_614d8b48(app.context, inject)
  }

  if (typeof nuxt_plugin_coremiddleware560ebda2_3446965e === 'function') {
    await nuxt_plugin_coremiddleware560ebda2_3446965e(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_pluginsvueDatepicker62cb7a72_1004f70d === 'function') {
    await nuxt_plugin_pluginsvueDatepicker62cb7a72_1004f70d(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginsvue2smoothscroll997f738c_37c58748 === 'function') {
    await nuxt_plugin_pluginsvue2smoothscroll997f738c_37c58748(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginsglobale993087c_3f21e530 === 'function') {
    await nuxt_plugin_pluginsglobale993087c_3f21e530(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginutils_5b7dde8a === 'function') {
    await nuxt_plugin_pluginutils_5b7dde8a(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_2980f155 === 'function') {
    await nuxt_plugin_pluginrouting_2980f155(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_9196fb8c === 'function') {
    await nuxt_plugin_pluginmain_9196fb8c(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_5d7b6979 === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_5d7b6979(app.context, inject)
  }

  if (typeof nuxt_plugin_coreplugin1596bee1_195bb8ab === 'function') {
    await nuxt_plugin_coreplugin1596bee1_195bb8ab(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginsi18n92442a32_9386f104 === 'function') {
    await nuxt_plugin_pluginsi18n92442a32_9386f104(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginsclientHandler741d148a_53deaeae === 'function') {
    await nuxt_plugin_pluginsclientHandler741d148a_53deaeae(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginsrealTimeHandler6d8eddca_2071ffd1 === 'function') {
    await nuxt_plugin_pluginsrealTimeHandler6d8eddca_2071ffd1(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginshasFeature69d8fec9_eac4f2d2 === 'function') {
    await nuxt_plugin_pluginshasFeature69d8fec9_eac4f2d2(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginspermissionscfa18936_f5af86d0 === 'function') {
    await nuxt_plugin_pluginspermissionscfa18936_f5af86d0(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginsfeatureFlags4bc98cf4_3aa51ddb === 'function') {
    await nuxt_plugin_pluginsfeatureFlags4bc98cf4_3aa51ddb(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginspapa7e2162e3_a5e7851c === 'function') {
    await nuxt_plugin_pluginspapa7e2162e3_a5e7851c(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginsensureRendere98fe85e_3ba02cd6 === 'function') {
    await nuxt_plugin_pluginsensureRendere98fe85e_3ba02cd6(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginsposthogda35486e_76a5190e === 'function') {
    await nuxt_plugin_pluginsposthogda35486e_76a5190e(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginsrouter7e227e5c_fa580ef4 === 'function') {
    await nuxt_plugin_pluginsrouter7e227e5c_fa580ef4(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginsversion323b68de_2855481c === 'function') {
    await nuxt_plugin_pluginsversion323b68de_2855481c(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginsrouteMounted7dacfd14_77153546 === 'function') {
    await nuxt_plugin_pluginsrouteMounted7dacfd14_77153546(app.context, inject)
  }

  if (typeof nuxt_plugin_databaseplugin16300186_2f38d45e === 'function') {
    await nuxt_plugin_databaseplugin16300186_2f38d45e(app.context, inject)
  }

  if (typeof nuxt_plugin_integrationsplugin3a94913e_449f62b0 === 'function') {
    await nuxt_plugin_integrationsplugin3a94913e_449f62b0(app.context, inject)
  }

  if (typeof nuxt_plugin_builderplugin4ddb09e3_7bdbe47a === 'function') {
    await nuxt_plugin_builderplugin4ddb09e3_7bdbe47a(app.context, inject)
  }

  if (typeof nuxt_plugin_dashboardplugin598e5e48_632390be === 'function') {
    await nuxt_plugin_dashboardplugin598e5e48_632390be(app.context, inject)
  }

  if (typeof nuxt_plugin_automationplugine5deaece_91d132e8 === 'function') {
    await nuxt_plugin_automationplugine5deaece_91d132e8(app.context, inject)
  }

  if (typeof nuxt_plugin_baserowpremiumplugina83aa5fe_21c7bb3e === 'function') {
    await nuxt_plugin_baserowpremiumplugina83aa5fe_21c7bb3e(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginslicense4d119530_b9e056fa === 'function') {
    await nuxt_plugin_pluginslicense4d119530_b9e056fa(app.context, inject)
  }

  if (typeof nuxt_plugin_baserowenterpriseplugin6761bca9_293df3ae === 'function') {
    await nuxt_plugin_baserowenterpriseplugin6761bca9_293df3ae(app.context, inject)
  }

  if (typeof nuxt_plugin_webfrontendplugin9cf14ee4_6df24c3a === 'function') {
    await nuxt_plugin_webfrontendplugin9cf14ee4_6df24c3a(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
