var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"saas-dashboard__row-usage"},[_c('div',{staticClass:"saas-dashboard__row-usage-progress-bar"},[_c('div',{staticClass:"saas-dashboard__row-usage-text"},[_vm._v("\n      "+_vm._s(_vm.$t('common.rowUsage'))+"\n      "),_c('div',{staticClass:"saas-dashboard__row-usage-count"},[_c('strong',[_vm._v(_vm._s(_vm.workspaceUsage.row_count))]),_vm._v(" "),_c('span',[_vm._v("/ "+_vm._s(_vm.workspaceUsage.rows_quota))])])]),_vm._v(" "),_c('ProgressBar',{staticClass:"saas-dashboard__row-usage-progress-bar-component",attrs:{"progress":_vm.workspaceUsage.row_count,"total":_vm.workspaceUsage.rows_quota}})],1),_vm._v(" "),(
      _vm.workspaceUsage.license_type === null &&
      _vm.workspaceUsage.user_can_manage_subscription &&
      _vm.workspaceUsage.previous_trial === false
    )?[_c('Button',{attrs:{"type":"secondary"},on:{"click":function($event){return _vm.$refs.startTrialModal.show()}}},[_vm._v("\n      "+_vm._s(_vm.$t('trials.getFreeTrial'))+"\n    ")]),_vm._v(" "),_c('StartTrialModal',{ref:"startTrialModal",attrs:{"workspace":_vm.workspace},on:{"success":function($event){return _vm.trialStarted()}}})]:(_vm.workspaceUsage.user_can_manage_subscription)?_c('Button',{attrs:{"type":"secondary"},on:{"click":_vm.upgrade}},[_vm._v("\n    "+_vm._s(_vm.$t('common.changePlan')))]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"tooltip tooltip--center saas-dashboard__row-usage-tooltip"},[_c('div',{staticClass:"tooltip__content saas-dashboard__usage-tooltip-content"},[_c('ul',{staticClass:"saas-dashboard__usage-tooltip-list"},[_vm._l((_vm.features),function(feature){return _c('li',{key:feature.name,staticClass:"saas-dashboard__usage-tooltip-list-item"},[_vm._v("\n          "+_vm._s(feature.name)+"\n          "),_c('i',{staticClass:"saas-dashboard__usage-tooltip-list-item-icon",class:{
              'saas-dashboard__usage-tooltip-list-item-icon--available iconoir-check':
                feature.available,
              'saas-dashboard__usage-tooltip-list-item-icon--not-available iconoir-cancel':
                !feature.available,
            }})])}),_vm._v(" "),_c('li',{staticClass:"saas-dashboard__usage-tooltip-list-item"},[_vm._v("\n          "+_vm._s(_vm.$t('common.storageUsage'))+"\n          "),_c('span',{staticClass:"saas-dashboard__usage-tooltip-list-item-value"},[_c('strong',[_vm._v(_vm._s(_vm.storageUsageInGB(_vm.workspaceUsage))+_vm._s(_vm.$t('common.gb')))]),_vm._v("\n            /\n            "),_c('small',[_vm._v(_vm._s(_vm.workspaceUsage.storage_quota)+_vm._s(_vm.$t('common.gb')))])])]),_vm._v(" "),_c('li',{staticClass:"saas-dashboard__usage-tooltip-list-item"},[_vm._v("\n          "+_vm._s(_vm.$t('common.rowUsage'))+"\n          "),_c('span',{staticClass:"saas-dashboard__usage-tooltip-list-item-value"},[_c('strong',[_vm._v(_vm._s(_vm.workspaceUsage.row_count))]),_vm._v(" /\n            "),_c('small',[_vm._v(_vm._s(_vm.workspaceUsage.rows_quota))])])]),_vm._v(" "),_c('li',{staticClass:"saas-dashboard__usage-tooltip-list-item"},[_vm._v("\n          "+_vm._s(_vm.$t('common.pageViewUsage'))+"\n          "),_c('span',{staticClass:"saas-dashboard__usage-tooltip-list-item-value"},[_c('strong',[_vm._v(_vm._s(_vm.workspaceUsage.page_views_count))]),_vm._v(" /\n            "),_c('small',[_vm._v(_vm._s(_vm.workspaceUsage.page_views_quota))])])]),_vm._v(" "),_c('li',{staticClass:"saas-dashboard__usage-tooltip-list-item"},[_vm._v("\n          "+_vm._s(_vm.$t('common.applicationUserUsage'))+"\n          "),_c('span',{staticClass:"saas-dashboard__usage-tooltip-list-item-value"},[_c('strong',[_vm._v(_vm._s(_vm.workspaceUsage.application_users_count))]),_vm._v(" /\n            "),_c('small',[_vm._v(_vm._s(_vm.workspaceUsage.application_users_quota))])])])],2)])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }