var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('p',{staticClass:"margin-bottom-2"},[_vm._v("\n    "+_vm._s(_vm.$t('importFromAirtable.airtableShareLinkDescription'))+"\n    "),_c('br'),_c('br'),_vm._v("\n    "+_vm._s(_vm.$t('importFromAirtable.airtableShareLinkBeta'))+"\n  ")]),_vm._v(" "),_c('FormGroup',{staticClass:"margin-bottom-2",attrs:{"label":_vm.$t('importFromAirtable.airtableShareLinkTitle'),"error":_vm.v$.values.airtableUrl.$error,"small-label":"","required":""},scopedSlots:_vm._u([{key:"error",fn:function(){return [_vm._v("\n      "+_vm._s(_vm.$t('importFromAirtable.linkError'))+"\n    ")]},proxy:true}])},[_c('FormInput',{attrs:{"error":_vm.v$.values.airtableUrl.$error,"placeholder":_vm.$t('importFromAirtable.airtableShareLinkPaste'),"size":"large"},on:{"blur":_vm.v$.values.airtableUrl.$touch},model:{value:(_vm.v$.values.airtableUrl.$model),callback:function ($$v) {_vm.$set(_vm.v$.values.airtableUrl, "$model", $$v)},expression:"v$.values.airtableUrl.$model"}})],1),_vm._v(" "),_c('div',{staticClass:"margin-bottom-2"},[_c('Checkbox',{model:{value:(_vm.values.skipFiles),callback:function ($$v) {_vm.$set(_vm.values, "skipFiles", $$v)},expression:"values.skipFiles"}},[_vm._v("\n      "+_vm._s(_vm.$t('importFromAirtable.skipFiles'))+"\n      "),_c('HelpIcon',{attrs:{"tooltip":_vm.$t('importFromAirtable.skipFilesHelper'),"tooltip-content-type":'plain',"tooltip-content-classes":[
          'tooltip__content--expandable',
          'tooltip__content--expandable-plain-text',
        ],"icon":'info-empty'}})],1)],1),_vm._v(" "),_c('div',{staticClass:"margin-bottom-2"},[_c('Checkbox',{model:{value:(_vm.values.useSession),callback:function ($$v) {_vm.$set(_vm.values, "useSession", $$v)},expression:"values.useSession"}},[_vm._v("\n      "+_vm._s(_vm.$t('importFromAirtable.useSession'))+"\n      "),_c('HelpIcon',{attrs:{"tooltip":_vm.$t('importFromAirtable.useSessionHelper'),"tooltip-content-type":'plain',"tooltip-content-classes":[
          'tooltip__content--expandable',
          'tooltip__content--expandable-plain-text',
        ],"icon":'info-empty'}})],1)],1),_vm._v(" "),(_vm.values.useSession)?_c('div',{staticClass:"margin-bottom-2"},[_c('p',{staticClass:"margin-bottom-2"},[_vm._v("\n      "+_vm._s(_vm.$t('importFromAirtable.sessionDescription'))+"\n    ")]),_vm._v(" "),_c('FormGroup',{staticClass:"margin-bottom-2",attrs:{"label":_vm.$t('importFromAirtable.sessionLabel'),"error":_vm.v$.values.session.$error,"small-label":"","required":""}},[_c('FormInput',{attrs:{"error":_vm.v$.values.session.$error,"placeholder":"eyJzZXNz...","size":"large"},on:{"blur":_vm.v$.values.session.$touch},model:{value:(_vm.v$.values.session.$model),callback:function ($$v) {_vm.$set(_vm.v$.values.session, "$model", $$v)},expression:"v$.values.session.$model"}})],1),_vm._v(" "),_c('FormGroup',{staticClass:"margin-bottom-2",attrs:{"label":_vm.$t('importFromAirtable.sessionSignatureLabel'),"error":_vm.v$.values.sessionSignature.$error,"small-label":"","required":""}},[_c('FormInput',{attrs:{"error":_vm.v$.values.session.$error,"placeholder":"OYncZ-Nz...","size":"large"},on:{"blur":_vm.v$.values.sessionSignature.$touch},model:{value:(_vm.v$.values.sessionSignature.$model),callback:function ($$v) {_vm.$set(_vm.v$.values.sessionSignature, "$model", $$v)},expression:"v$.values.sessionSignature.$model"}})],1)],1):_vm._e(),_vm._v(" "),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }