<template>
  <footer>
    <div class="saas-footer">
      <div class="saas-footer__separator"></div>
      <div class="saas-footer__inner">
        <div class="saas-footer__content">
          <div class="saas-footer__group">
            <div class="saas-footer__group-top">
              <nuxt-link class="saas-footer__logo" :to="{ name: 'index' }">
                <img
                  src="@baserow/modules/core/static/img/logo-white.svg"
                  alt="Baserow logo"
                />
              </nuxt-link>
            </div>
            <div class="saas-footer__group-title">Company</div>
            <ul class="saas-footer__links">
              <li v-for="aboutUsPage in aboutUsPages" :key="aboutUsPage.path">
                <nuxt-link :to="aboutUsPage.path">
                  {{ aboutUsPage.title }}
                </nuxt-link>
              </li>
              <li
                v-for="jobListingPage in jobListingPages"
                :key="jobListingPage.path"
              >
                <nuxt-link :to="jobListingPage.path">
                  {{ jobListingPage.title }}
                </nuxt-link>
              </li>
              <li>
                <nuxt-link :to="{ name: 'faq' }">FAQ</nuxt-link>
              </li>
              <li>
                <nuxt-link :to="{ name: 'partners' }">Partners</nuxt-link>
              </li>
              <li>
                <nuxt-link :to="{ name: 'contact' }">Contact</nuxt-link>
              </li>
              <li>
                <a
                  href="https://status.baserow.org/"
                  rel="noopener noreferrer"
                  target="_blank"
                  >Status</a
                >
              </li>
            </ul>
          </div>
          <div class="saas-footer__group">
            <div class="saas-footer__group-title">Platform</div>
            <ul class="saas-footer__links">
              <li>
                <nuxt-link :to="{ name: 'templates' }">Templates</nuxt-link>
              </li>
              <li
                v-for="enterprisePage in enterprisePages"
                :key="enterprisePage.path"
              >
                <nuxt-link :to="enterprisePage.path" class="saas-footer__link">
                  {{ enterprisePage.title }}
                </nuxt-link>
              </li>
              <li>
                <nuxt-link :to="{ name: 'pricing' }">Pricing</nuxt-link>
              </li>
              <li
                v-for="communityPage in communityPages"
                :key="communityPage.path"
              >
                <nuxt-link :to="communityPage.path">
                  {{ communityPage.title }}
                </nuxt-link>
              </li>
              <li>
                <nuxt-link :to="{ name: 'user-docs' }"
                  >Knowledge base</nuxt-link
                >
              </li>
              <li>
                <a
                  href="https://n8n.io/integrations/baserow/"
                  title="Baserow n8n integration"
                  rel="noopener noreferrer"
                  target="_blank"
                  >n8n integration</a
                >
              </li>
              <li>
                <a
                  href="https://zapier.com/apps/baserow/integrations"
                  title="Baserow Zapier integration"
                  rel="noopener noreferrer"
                  target="_blank"
                  >Zapier integration</a
                >
              </li>
              <li>
                <a
                  href="https://www.make.com/en/integrations/baserow"
                  title="Baserow Make integration"
                  rel="noopener noreferrer"
                  target="_blank"
                  >Make integration</a
                >
              </li>
              <li
                v-for="integrationPage in integrationPages"
                :key="integrationPage.path"
              >
                <nuxt-link :to="integrationPage.path" class="saas-footer__link">
                  All integrations
                </nuxt-link>
              </li>
            </ul>
          </div>
          <div class="saas-footer__group">
            <div class="saas-footer__group-title">Blog</div>
            <ul class="saas-footer__links">
              <li
                v-for="summary in highlightedBlogSummaries"
                :key="summary.slug"
              >
                <nuxt-link
                  :to="{
                    name: 'blog-post-page',
                    params: {
                      blogPostSlug: summary.slug,
                    },
                  }"
                  >{{ summary.short_title }}</nuxt-link
                >
              </li>
            </ul>
          </div>
          <div class="saas-footer__group">
            <div class="saas-footer__group-top">
              <a
                href="https://gitlab.com/baserow/baserow"
                title="Baserow repository at GitLab"
                rel="noopener noreferrer"
                target="_blank"
                class="saas-footer__social"
              >
                <i class="baserow-icon-gitlab"></i>
              </a>
              <a
                href="https://twitter.com/baserow"
                title="Baserow Twitter profile"
                rel="noopener noreferrer"
                target="_blank"
                class="saas-footer__social"
              >
                <i class="baserow-icon-twitter"></i>
              </a>
              <a
                href="https://www.linkedin.com/company/baserow/"
                title="Baserow LinkedIn profile"
                rel="noopener noreferrer"
                target="_blank"
                class="saas-footer__social"
              >
                <i class="baserow-icon-linkedin"></i>
              </a>
              <a
                href="https://community.baserow.io/"
                title="Baserow community forum"
                rel="noopener noreferrer"
                target="_blank"
                class="saas-footer__social"
              >
                <i class="iconoir-chat-bubble-question"></i>
              </a>
            </div>
            <div class="saas-footer__group-title">For developers</div>
            <ul class="saas-footer__links">
              <li>
                <nuxt-link
                  :to="{
                    name: 'dev-docs',
                    params: {
                      path: 'index',
                    },
                  }"
                  >Documentation</nuxt-link
                >
              </li>
              <li>
                <nuxt-link
                  :to="{ name: 'database-api-docs' }"
                  class="saas-footer__link"
                  >API</nuxt-link
                >
              </li>
              <li>
                <a
                  :href="$config.PUBLIC_BACKEND_URL + '/api/redoc/'"
                  target="_blank"
                  >OpenAPI</a
                >
              </li>
            </ul>
            <div class="saas-footer__group-title margin-top-4 margin-bottom-1">
              Join our newsletter
            </div>
            <div class="saas-footer__group-description">
              Stay up to date with the latest developments and releases by
              signing up for our newsletter.
            </div>
            <NewsletterSignup :dark="true"></NewsletterSignup>
          </div>
        </div>
      </div>
      <div class="saas-footer__separator"></div>
      <div class="saas-footer__inner">
        <div class="saas-footer__legal">
          <div>
            &copy; {{ new Date().getFullYear() }} Baserow All rights reserved.
          </div>
          <div>
            <nuxt-link :to="{ name: 'terms' }">Terms & conditions</nuxt-link>
            <nuxt-link :to="{ name: 'privacy' }">Privacy policy</nuxt-link>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex'

import NewsletterSignup from '@saas/components/NewsletterSignup'

export default {
  components: { NewsletterSignup },
  computed: {
    enterprisePages() {
      return this.$store.getters['saasLayout/getMenuPagesPerType'](
        'saas_cms.EnterprisePage'
      )
    },
    aboutUsPages() {
      return this.$store.getters['saasLayout/getMenuPagesPerType'](
        'saas_cms.AboutUsPage'
      )
    },
    jobListingPages() {
      return this.$store.getters['saasLayout/getMenuPagesPerType'](
        'saas_cms.JobsListingPage'
      )
    },
    communityPages() {
      return this.$store.getters['saasLayout/getMenuPagesPerType'](
        'saas_cms.CommunityPage'
      )
    },
    integrationPages() {
      return this.$store.getters['saasLayout/getMenuPagesPerType'](
        'saas_cms.IntegrationsPage'
      )
    },
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
      highlightedBlogSummaries: 'saasLayout/getHighlighted',
    }),
  },
}
</script>
