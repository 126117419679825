var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'menu-element__container',
    _vm.element.orientation === 'horizontal'
      ? 'menu-element__container--horizontal'
      : 'menu-element__container--vertical',
  ],style:({
    '--alignment': _vm.menuAlignment,
  })},[_vm._l((_vm.element.menu_items),function(item){return _c('div',{key:item.id,class:`menu-element__menu-item-${item.type}`},[(item.type === 'link' && !item.parent_menu_item)?[(!item.children?.length)?_c('div',{style:(_vm.getStyleOverride('menu'))},[_c('ABLink',{attrs:{"variant":item.variant,"url":_vm.getItemUrl(item),"target":_vm.getMenuItem(item).target,"force-active":_vm.menuItemIsActive(item)}},[_vm._v("\n          "+_vm._s(item.name
              ? item.name ||
                (_vm.mode === 'editing'
                  ? _vm.$t('menuElement.emptyLinkValue')
                  : ' ')
              : _vm.$t('menuElement.missingLinkValue'))+"\n        ")])],1):_c('div',{ref:"menuSubLinkContainer",refInFor:true,on:{"click":function($event){return _vm.showSubMenu($event, item.id)}}},[_c('div',{style:(_vm.getStyleOverride('menu'))},[_c('ABLink',{attrs:{"variant":item.variant,"url":"","force-active":_vm.sublinkIsActive(item)}},[_c('div',{staticClass:"menu-element__sub-link-menu--container"},[_vm._v("\n              "+_vm._s(item.name)+"\n              "),_c('div',{staticClass:"menu-element__sub-link-menu-spacer"}),_vm._v(" "),_c('div',[_c('i',{staticClass:"menu-element__sub-link--expanded-icon",class:_vm.isExpanded(item.id)
                      ? 'iconoir-nav-arrow-up'
                      : 'iconoir-nav-arrow-down'})])])])],1),_vm._v(" "),_c('Context',{ref:`subLinkContext_${item.id}`,refInFor:true,attrs:{"hide-on-click-outside":true},on:{"shown":function($event){return _vm.toggleExpanded(item.id)},"hidden":function($event){return _vm.toggleExpanded(item.id)}}},[_c('ThemeProvider',{staticClass:"menu-element__sub-links"},_vm._l((item.children),function(child){return _c('div',{key:child.id,style:(_vm.getStyleOverride('menu'))},[_c('ABLink',{staticClass:"menu-element__sub-link",attrs:{"variant":child.variant,"url":_vm.getItemUrl(child),"target":_vm.getMenuItem(child).target,"force-active":_vm.menuItemIsActive(child)}},[_vm._v("\n                "+_vm._s(child.name
                    ? child.name ||
                      (_vm.mode === 'editing'
                        ? _vm.$t('menuElement.emptyLinkValue')
                        : ' ')
                    : _vm.$t('menuElement.missingLinkValue'))+"\n              ")])],1)}),0)],1)],1)]:(item.type === 'button')?[_c('ABButton',{style:(_vm.getStyleOverride('menu')),on:{"click":function($event){return _vm.onButtonClick(item)}}},[_vm._v("\n        "+_vm._s(item.name
            ? item.name ||
              (_vm.mode === 'editing'
                ? _vm.$t('menuElement.emptyButtonValue')
                : ' ')
            : _vm.$t('menuElement.missingButtonValue'))+"\n      ")])]:_vm._e()],2)}),_vm._v(" "),(!_vm.element.menu_items.length)?_c('div',{staticClass:"element--no-value"},[_vm._v("\n    "+_vm._s(_vm.$t('menuElement.missingValue'))+"\n  ")]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }