var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"data-source-item"},[_c('Presentation',{staticClass:"flex-grow-1",attrs:{"title":_vm.dataSource.name,"size":"medium","image":!_vm.isInError ? _vm.image : null,"icon":_vm.isInError ? 'iconoir-warning-circle' : null,"initials":_vm.image ? null : '?',"subtitle":_vm.subtitle,"rounded-icon":false,"avatar-color":_vm.isInError ? 'red' : 'neutral'},on:{"click":function($event){_vm.$hasPermission(
        'builder.page.data_source.update',
        _vm.dataSource,
        _vm.workspace.id
      ) && _vm.$emit('edit', _vm.dataSource)}}}),_vm._v(" "),(
      _vm.$hasPermission(
        'builder.page.data_source.update',
        _vm.dataSource,
        _vm.workspace.id
      )
    )?_c('div',{staticClass:"data-source-item__actions"},[_c('ButtonIcon',{attrs:{"icon":"iconoir-edit"},on:{"click":function($event){return _vm.$emit('edit', _vm.dataSource)}}}),_vm._v(" "),_c('ButtonIcon',{ref:"contextOpener",attrs:{"type":"secondary","icon":"iconoir-more-vert"},on:{"click":function($event){return _vm.$refs.dataSourceItemContext.toggle(
          _vm.$refs.contextOpener.$el,
          'bottom',
          'right',
          4
        )}}})],1):_vm._e(),_vm._v(" "),_c('DataSourceItemContext',{ref:"dataSourceItemContext",attrs:{"shared":_vm.shared},on:{"delete":function($event){return _vm.$emit('delete', _vm.dataSource)},"share":function($event){return _vm.$emit('share', _vm.dataSource)}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }