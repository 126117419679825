var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"webhook",class:{ 'webhook--open': _vm.isExpanded }},[_c('div',{staticClass:"webhook__head"},[_c('div',{staticClass:"webhook__head-left"},[_c('div',{staticClass:"webhook__head-name"},[_vm._v("\n        "+_vm._s(_vm.webhook.name)+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"webhook__head-details"},[_c('div',{staticClass:"webhook__head-details-target"},[_vm._v("\n          "+_vm._s(_vm.webhook.url)+"\n        ")]),_vm._v(" "),_c('a',{staticClass:"webhook__head-toggle",attrs:{"href":"#"},on:{"click":function($event){return _vm.toggleExpand()}}},[_vm._v("\n          "+_vm._s(_vm.$t('webhook.details'))+"\n          "),_c('i',{staticClass:"webhook__head-toggle-icon",class:{
              'iconoir-nav-arrow-down': !_vm.isExpanded,
              'iconoir-nav-arrow-up': _vm.isExpanded,
            }})])])]),_vm._v(" "),_c('div',{staticClass:"webhook__head-right"},[_c('div',{staticClass:"webhook__head-trigger"},[_vm._v("\n        "+_vm._s(_vm.$tc('webhook.triggerDescription', _vm.calculateNumberOfEvents, {
            count: _vm.calculateNumberOfEvents,
          }))+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"webhook__head-call"},[_c('div',{staticClass:"webhook__head-date"},[_vm._v("\n          "+_vm._s(_vm.$t('webhook.lastCall', { lastCallTime: _vm.lastCallTime }))+"\n        ")]),_vm._v(" "),_c('span',{staticClass:"webhook__head-call-state",class:{
            'webhook__head-call-state--ok': _vm.lastCallOk,
            'webhook__head-call-state--error': !_vm.lastCallOk,
          }},[_vm._v(_vm._s(_vm.lastCallStatusDescription))])])])]),_vm._v(" "),_c('div',{staticClass:"webhook__body"},[_c('Tabs',[_c('Tab',{attrs:{"title":_vm.$t('action.edit')}},[_c('UpdateWebhook',{attrs:{"webhook":_vm.webhook,"database":_vm.database,"table":_vm.table,"fields":_vm.fields,"views":_vm.views},on:{"updated":function($event){return _vm.$emit('updated', $event)},"deleted":function($event){return _vm.$emit('deleted', $event)}}})],1),_vm._v(" "),_c('Tab',{attrs:{"title":_vm.$t('webhook.callLog')}},[(_vm.webhook.calls.length <= 0)?_c('p',[_vm._v(_vm._s(_vm.$t('webhook.noCalls')))]):_vm._e(),_vm._v(" "),_vm._l((_vm.webhook.calls),function(call){return _c('WebhookCall',{key:call.id,attrs:{"call":call}})})],2)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }