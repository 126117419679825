import { render, staticRenderFns } from "./CheckboxElementForm.vue?vue&type=template&id=787d4413"
import script from "./CheckboxElementForm.vue?vue&type=script&lang=js"
export * from "./CheckboxElementForm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../../baserow-saas/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports