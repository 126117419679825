var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"grid-view__head"},[_vm._l((_vm.includeGroupBy ? _vm.activeGroupBys : []),function(groupBy){return _c('div',{key:'field-group-' + groupBy.field,staticClass:"grid-view__head-group",style:({ width: groupBy.width + 'px' }),attrs:{"set":(_vm.field = _vm.$options.methods.getField(_vm.allFieldsInTable, groupBy))}},[_c('div',{staticClass:"grid-view__group-cell"},[_c('div',{staticClass:"grid-view__group-name"},[_vm._v("\n        "+_vm._s(_vm.field.name)+"\n      ")])])])}),_vm._v(" "),(_vm.includeRowDetails)?_c('div',{staticClass:"grid-view__column grid-view__column--no-border-right",style:({ width: _vm.gridViewRowDetailsWidth + 'px' })},[(
        !_vm.readOnly &&
        _vm.$hasPermission(
          'database.table.view.update',
          _vm.view,
          _vm.database.workspace.id
        )
      )?_c('GridViewRowIdentifierDropdown',{attrs:{"row-identifier-type-selected":_vm.view.row_identifier_type},on:{"change":_vm.onChangeIdentifierDropdown}}):_vm._e()],1):_vm._e(),_vm._v(" "),_vm._l((_vm.visibleFields),function(field){return _c('GridViewFieldType',{key:'field-type-' + field.id,attrs:{"database":_vm.database,"table":_vm.table,"view":_vm.view,"field":field,"all-fields-in-table":_vm.allFieldsInTable,"filters":_vm.view.filters,"include-field-width-handles":_vm.includeFieldWidthHandles,"read-only":_vm.readOnly,"store-prefix":_vm.storePrefix},on:{"refresh":function($event){return _vm.$emit('refresh', $event)},"dragging":function($event){return _vm.$emit('dragging', $event)},"field-created":function($event){return _vm.$emit('field-created', $event)},"move-field":_vm.moveField}})}),_vm._v(" "),(
      _vm.includeAddField &&
      !_vm.readOnly &&
      _vm.$hasPermission(
        'database.table.create_field',
        _vm.table,
        _vm.database.workspace.id
      )
    )?_c('div',{staticClass:"grid-view__column",style:({ width: 100 + 'px' })},[_c('a',{ref:"createFieldContextLink",staticClass:"grid-view__add-column",attrs:{"data-highlight":"add-field"},on:{"click":function($event){return _vm.$refs.createFieldContext.toggle(_vm.$refs.createFieldContextLink)}}},[_c('i',{staticClass:"grid-view__add-column-icon iconoir-plus"})]),_vm._v(" "),_c('CreateFieldContext',{ref:"createFieldContext",attrs:{"table":_vm.table,"view":_vm.view,"all-fields-in-table":_vm.allFieldsInTable,"database":_vm.database},on:{"field-created":function($event){return _vm.$emit('field-created', $event)},"field-created-callback-done":_vm.afterFieldCreatedUpdateFieldOptions,"shown":_vm.onShownCreateFieldContext}})],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }