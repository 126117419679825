<template>
  <div>
    <div class="control">
      <div class="control-elements">
        <Checkbox v-model="v$.confirmed.$model" @input="v$.confirmed.$touch">
          <i18n path="additionalAuthControls.message" tag="span">
            <a :href="url" target="_blank" @click.stop>{{
              $t('additionalAuthControls.termsAndConditions')
            }}</a>
          </i18n>
        </Checkbox>
        <div v-if="v$.confirmed.$error" class="error">
          {{ $t('error.requiredField') }}
        </div>
        <Checkbox
          v-model="v$.newsletter.$model"
          class="margin-top-1"
          @input="
            $emit('updated-account', {
              key: 'newsletterSignup',
              value: $event,
            })
          "
        >
          {{ $t('additionalAuthControls.newsletter') }}
        </Checkbox>
      </div>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { reactive, getCurrentInstance } from 'vue'
import { helpers } from '@vuelidate/validators'
export default {
  name: 'AdditionalAuthControls',
  setup() {
    const instance = getCurrentInstance()
    const values = reactive({
      confirmed: false,
      newsletter: false,
    })

    const rules = {
      confirmed: {
        required: helpers.withMessage(
          instance.proxy.$t('error.requiredField'),
          (value) => !!value
        ),
      },
      newsletter: {},
    }

    return {
      v$: useVuelidate(rules, values, { $lazy: true }),
      confirmed: values.confirmed,
      newsletter: values.newsletter,
    }
  },

  computed: {
    url() {
      return this.$nuxt.$router.resolve({
        name: 'terms',
      }).href
    },
  },
  methods: {
    isValid() {
      this.v$.$touch()
      return !this.v$.$invalid
    },
  },
}
</script>
