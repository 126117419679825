var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a',_vm._g({class:[
    {
      'ab-link': _vm.variant !== 'button',
      'ab-button ab-button--medium': _vm.variant === 'button',
      'ab-button--full-width': _vm.variant === 'button' && _vm.fullWidth === true,
    },
    _vm.forceActiveClass,
  ],attrs:{"target":`_${_vm.target}`,"href":_vm.url,"rel":_vm.isExternalLink ? 'noopener noreferrer' : null},on:{"click":_vm.handleClick}},_vm.$listeners),[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }