<template>
  <div
    class="newsletter-signup"
    :class="{
      'newsletter-signup--vertical': direction === 'vertical',
    }"
  >
    <form
      class="newsletter-signup-form"
      :class="{
        'newsletter-signup-form--dark': dark,
        'newsletter-signup-form--vertical': direction === 'vertical',
      }"
      @submit.prevent="!success && newsletterSubmitted($event)"
    >
      <div class="newsletter-signup-form__input-wrapper">
        <FormInput
          ref="email"
          v-model="newsletter.email"
          name="EMAIL"
          class="newsletter-signup-form__input"
          :error="v$.newsletter.email.$error"
          type="email"
          :size="size"
          placeholder="Your email address"
          :disabled="success"
          @blur="v$.newsletter.email.$touch"
        />
      </div>

      <Button
        :loading="loading"
        :disabled="success"
        :type="dark ? 'secondary' : 'primary'"
        :size="size"
        :full-width="direction === 'vertical'"
        >Subscribe</Button
      >
    </form>
    <div v-if="v$.newsletter.email.$error" class="newsletter-signup__error">
      The provided email address is invalid.
    </div>
    <div v-if="success" class="newsletter-signup__success">
      You've been signed up for the newsletter successfully.
    </div>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { reactive } from 'vue'
import { required, email } from '@vuelidate/validators'
import NewsletterService from '@saas/services/newsletter'

export default {
  name: 'NewsletterSignup',
  props: {
    dark: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    size: {
      type: String,
      required: false,
      default: () => 'large',
      validator: (value) => ['regular', 'large', 'xlarge'].includes(value),
    },
    direction: {
      type: String,
      required: false,
      default: () => 'horizontal',
      validator: (value) => ['horizontal', 'vertical'].includes(value),
    },
  },
  setup() {
    const values = reactive({
      newsletter: {
        email: '',
      },
    })

    const rules = {
      newsletter: {
        email: { required, email },
      },
    }

    return {
      v$: useVuelidate(rules, values, { $lazy: true }),
      newsletter: values.newsletter,
    }
  },
  data() {
    return {
      loading: false,
      success: false,
      email: '',
    }
  },
  methods: {
    async newsletterSubmitted(event) {
      this.v$.$touch()
      if (this.v$.$invalid) {
        event.preventDefault()
        this.$refs.email.focus()
        return
      }

      this.loading = true

      try {
        await NewsletterService(this.$client).subscribe(this.newsletter.email)
        this.success = true
        this.$posthog?.capture('newsletter_signup', { via_frontend: true })
      } catch (error) {}

      this.loading = false
    },
  },
}
</script>
