import EnterpriseFeatures from '@baserow_enterprise/features'
import PremiumFeatures from '@baserow_premium/features'
import { LicenseType } from '@baserow_premium/licenseTypes'

export class AdvancedLicenseType extends LicenseType {
  static getType() {
    return 'advanced'
  }

  getName() {
    const { i18n } = this.app
    return i18n.t('licenses.advanced')
  }

  getLicenseBadgeColor() {
    return 'magenta'
  }

  getFeatures() {
    return [
      PremiumFeatures.PREMIUM,
      EnterpriseFeatures.RBAC,
      EnterpriseFeatures.TEAMS,
      EnterpriseFeatures.SUPPORT,
      EnterpriseFeatures.AUDIT_LOG,
      EnterpriseFeatures.DATA_SYNC,
      EnterpriseFeatures.BUILDER_SSO,
      EnterpriseFeatures.CHART_WIDGET,
      EnterpriseFeatures.ADVANCED_WEBHOOKS,
    ]
  }

  getTopSidebarTooltip() {
    return ''
  }

  getFeaturesDescription() {
    return ''
  }

  showInTopSidebarWhenActive() {
    return false
  }

  getOrder() {
    return 50
  }

  getSeatsManuallyAssigned() {
    return false
  }

  getLicenseDescription(license) {
    // Should never be displayed as only used in the staff level licenses pages.
    return ''
  }

  getLicenseSeatOverflowWarning(license) {
    // Should never be displayed as only used in the staff level licenses pages.
    return ''
  }
}

export class AdvancedFreeTrialLicenseType extends AdvancedLicenseType {
  static getType() {
    return 'trial_advanced'
  }

  getOrder() {
    return 49
  }
}

export class AllSaasUsersLicenseType extends LicenseType {
  static getType() {
    return 'all_saas_users'
  }

  getName() {
    return ''
  }

  getLicenseBadgeColor() {
    return ''
  }

  getFeatures() {
    return [EnterpriseFeatures.SSO]
  }

  getTopSidebarTooltip() {
    return ''
  }

  getFeaturesDescription() {
    return 'SSO for everyone'
  }

  showInTopSidebarWhenActive() {
    return false
  }

  getOrder() {
    return -100
  }

  getSeatsManuallyAssigned() {
    return false
  }

  getLicenseDescription(license) {
    // Should never be displayed
    return ''
  }

  getLicenseSeatOverflowWarning(license) {
    // Should never be displayed
    return ''
  }
}

export class SaasStaffMembersLicense extends LicenseType {
  static getType() {
    return 'saas_staff_members'
  }

  getName() {
    return ''
  }

  getLicenseBadgeColor() {
    return ''
  }

  getFeatures() {
    return [EnterpriseFeatures.AUDIT_LOG]
  }

  getTopSidebarTooltip() {
    return ''
  }

  getFeaturesDescription() {
    return 'Audit log for Baserow.io staff members only'
  }

  showInTopSidebarWhenActive() {
    return false
  }

  getOrder() {
    return -100
  }

  getSeatsManuallyAssigned() {
    return false
  }

  getLicenseDescription(license) {
    // Should never be displayed
    return ''
  }

  getLicenseSeatOverflowWarning(license) {
    // Should never be displayed
    return ''
  }
}

