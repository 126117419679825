var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{on:{"submit":function($event){$event.preventDefault();},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();}}},[_c('CustomStyle',{attrs:{"style-key":"menu","config-block-types":['button', 'link'],"theme":_vm.builder.theme,"extra-args":{ noAlignment: true, noWidth: true }},model:{value:(_vm.values.styles),callback:function ($$v) {_vm.$set(_vm.values, "styles", $$v)},expression:"values.styles"}}),_vm._v(" "),_c('FormGroup',{staticClass:"margin-bottom-2",attrs:{"label":_vm.$t('orientations.label'),"small-label":"","required":""}},[_c('RadioGroup',{attrs:{"options":_vm.orientationOptions,"type":"button"},model:{value:(_vm.values.orientation),callback:function ($$v) {_vm.$set(_vm.values, "orientation", $$v)},expression:"values.orientation"}})],1),_vm._v(" "),(_vm.values.orientation === _vm.ORIENTATIONS.HORIZONTAL)?_c('FormGroup',{staticClass:"margin-bottom-2",attrs:{"label":_vm.$t('menuElementForm.alignment'),"small-label":"","required":""}},[_c('HorizontalAlignmentsSelector',{model:{value:(_vm.values.alignment),callback:function ($$v) {_vm.$set(_vm.values, "alignment", $$v)},expression:"values.alignment"}})],1):_vm._e(),_vm._v(" "),_c('div',{ref:"menuItemAddContainer",staticClass:"menu-element-form__add-item-container"},[_c('div',[_vm._v("\n      "+_vm._s(_vm.$t('menuElementForm.menuItemsLabel'))+"\n    ")]),_vm._v(" "),_c('div',[_c('ButtonText',{attrs:{"type":"primary","icon":"iconoir-plus","size":"small"},on:{"click":function($event){return _vm.$refs.menuItemAddContext.show(
            _vm.$refs.menuItemAddContainer,
            'bottom',
            'right'
          )}}},[_vm._v("\n        "+_vm._s(_vm.$t('menuElementForm.addMenuItemLink'))+"\n      ")])],1)]),_vm._v(" "),(!_vm.values.menu_items.length)?_c('p',[_vm._v("\n    "+_vm._s(_vm.$t('menuElementForm.noMenuItemsMessage'))+"\n  ")]):_vm._e(),_vm._v(" "),_c('Context',{ref:"menuItemAddContext",attrs:{"hide-on-click-outside":true}},[_c('div',{staticClass:"menu-element-form__add-item-context"},_vm._l((_vm.addMenuItemTypes),function(menuItemType,index){return _c('ButtonText',{key:index,attrs:{"type":"primary","icon":menuItemType.icon,"size":"small"},on:{"click":function($event){return _vm.addMenuItem(menuItemType.type)}}},[_vm._v("\n        "+_vm._s(menuItemType.label)+"\n      ")])}),1)]),_vm._v(" "),_c('div',[_c('div',{staticClass:"menu-element-form__items"},_vm._l((_vm.values.menu_items),function(item,index){return _c('MenuElementItemForm',{directives:[{name:"sortable",rawName:"v-sortable",value:({
          id: item.uid,
          update: _vm.orderRootItems,
          enabled: _vm.$hasPermission(
            'builder.page.element.update',
            _vm.element,
            _vm.workspace.id
          ),
          handle: '[data-sortable-handle]',
        }),expression:"{\n          id: item.uid,\n          update: orderRootItems,\n          enabled: $hasPermission(\n            'builder.page.element.update',\n            element,\n            workspace.id\n          ),\n          handle: '[data-sortable-handle]',\n        }"}],key:`${item.uid}-${index}`,attrs:{"default-values":item},on:{"remove-item":function($event){return _vm.removeMenuItem($event)},"values-changed":_vm.updateMenuItem}})}),1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }