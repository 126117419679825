var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"scroll",rawName:"v-scroll",value:(_vm.scroll),expression:"scroll"}],staticClass:"simple-grid",class:{
    'simple-grid--full-height': _vm.fullHeight,
    'simple-grid--with-footer': _vm.withFooter,
    'simple-grid--border': _vm.border,
  }},[(_vm.fixedFields.length > 0 || _vm.showRowId)?_c('div',{ref:"left",staticClass:"simple-grid__left"},[_c('div',{staticClass:"simple-grid__head"},[(_vm.showRowId)?_c('div',{staticClass:"simple-grid__field simple-grid__field--first"}):_vm._e(),_vm._v(" "),_vm._l((_vm.orderedFixedFields),function(field){return _c('div',{key:field.id,staticClass:"simple-grid__field",style:({ width: Math.max(_vm.getFieldWidth(field), 150) + 'px' })},[_c('div',{staticClass:"simple-grid__field-description"},[_c('i',{staticClass:"simple-grid__field-icon",class:_vm.fieldTypes[field.type].iconClass}),_vm._v("\n          "+_vm._s(field.name)+"\n        ")]),_vm._v(" "),_c('HorizontalResize',{staticClass:"simple-grid__field-width",attrs:{"width":_vm.getFieldWidth(field),"min":_vm.GRID_VIEW_MIN_FIELD_WIDTH},on:{"move":function($event){return _vm.moveFieldWidth(field, $event)},"update":function($event){return _vm.updateFieldWidth(field, $event)}}})],1)})],2),_vm._v(" "),_c('div',{staticClass:"simple-grid__body"},[_vm._l((_vm.rows),function(row){return _c('div',{key:row.id,staticClass:"simple-grid__row",class:{
          'simple-grid__row--hover':
            _vm.showHoveredRow && _vm.currentHoveredRow === row.id,
          'simple-grid__row--disabled':
            !_vm.multiple && _vm.selectedRows.includes(row.id),
          'simple-grid__row--checked':
            _vm.multiple && _vm.selectedRows.includes(row.id),
        },on:{"click":function($event){return _vm.$emit('row-click', row)},"mouseover":function($event){_vm.currentHoveredRow = row.id},"mouseleave":function($event){_vm.currentHoveredRow = null}}},[(_vm.showRowId)?_c('div',{staticClass:"simple-grid__cell simple-grid__cell--first"},[_vm._v("\n          "+_vm._s(row.id)+"\n          "),(_vm.multiple)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(
              _vm.currentHoveredRow === row.id || _vm.selectedRows.includes(row.id)
            ),expression:"\n              currentHoveredRow === row.id || selectedRows.includes(row.id)\n            "}],staticClass:"simple-grid__cell-checkbox"},[_c('Checkbox',{attrs:{"checked":_vm.selectedRows.includes(row.id)}})],1):_vm._e()]):_vm._e(),_vm._v(" "),_vm._l((_vm.orderedFixedFields),function(field){return _c('div',{key:field.id,staticClass:"simple-grid__cell",style:({ width: Math.max(_vm.getFieldWidth(field), 150) + 'px' })},[_c('SimpleGridField',{attrs:{"field":field,"row":row}})],1)})],2)}),_vm._v(" "),(_vm.canAddRow)?_c('div',{staticClass:"simple-grid__row",class:{
          'simple-grid__row--hover': _vm.showHoveredRow && _vm.addRowHover,
        },on:{"mouseover":function($event){_vm.addRowHover = true},"mouseleave":function($event){_vm.addRowHover = false},"click":function($event){return _vm.$emit('add-row')}}},[_vm._m(0)]):_vm._e(),_vm._v(" "),(_vm.withFooter)?_c('div',{staticClass:"simple-grid__foot"},[_vm._t("footLeft")],2):_vm._e()],2)]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"simple-grid__right-scrollbar-wrapper"},[_c('Scrollbars',{ref:"scrollbars",attrs:{"horizontal":"getHorizontalScrollbarElement"},on:{"horizontal":_vm.horizontalScroll}}),_vm._v(" "),_c('div',{ref:"right",staticClass:"simple-grid__right-wrapper"},[_c('div',{staticClass:"simple-grid__right"},[_c('div',{staticClass:"simple-grid__head"},_vm._l((_vm.visibleOrderedFields),function(field){return _c('div',{key:field.id,staticClass:"simple-grid__field",style:({ width: _vm.getFieldWidth(field) + 'px' })},[_c('div',{staticClass:"simple-grid__field-description"},[_c('i',{staticClass:"simple-grid__field-icon",class:_vm.fieldTypes[field.type].iconClass}),_vm._v("\n              "+_vm._s(field.name)+"\n            ")]),_vm._v(" "),_c('HorizontalResize',{staticClass:"simple-grid__field-width",attrs:{"width":_vm.getFieldWidth(field),"min":_vm.GRID_VIEW_MIN_FIELD_WIDTH},on:{"move":function($event){return _vm.moveFieldWidth(field, $event)},"update":function($event){return _vm.updateFieldWidth(field, $event)}}})],1)}),0),_vm._v(" "),_c('div',{staticClass:"simple-grid__body"},[_vm._l((_vm.rows),function(row){return _c('div',{key:row.id,staticClass:"simple-grid__row",class:{
              'simple-grid__row--hover':
                _vm.showHoveredRow && _vm.currentHoveredRow === row.id,
              'simple-grid__row--disabled':
                !_vm.multiple && _vm.selectedRows.includes(row.id),
              'simple-grid__row--checked':
                _vm.multiple && _vm.selectedRows.includes(row.id),
            },on:{"click":function($event){return _vm.$emit('row-click', row)},"mouseover":function($event){_vm.currentHoveredRow = row.id},"mouseleave":function($event){_vm.currentHoveredRow = null}}},_vm._l((_vm.visibleOrderedFields),function(field){return _c('div',{key:field.id,staticClass:"simple-grid__cell",style:({ width: _vm.getFieldWidth(field) + 'px' })},[_c('SimpleGridField',{attrs:{"field":field,"row":row}})],1)}),0)}),_vm._v(" "),(_vm.canAddRow && _vm.visibleOrderedFields.length > 0)?_c('div',{staticClass:"simple-grid__row",class:{
              'simple-grid__row--hover': _vm.showHoveredRow && _vm.addRowHover,
            },on:{"mouseover":function($event){_vm.addRowHover = true},"mouseleave":function($event){_vm.addRowHover = false},"click":function($event){return _vm.$emit('add-row')}}},[_c('div',{staticClass:"simple-grid__cell simple-grid__cell--single"})]):_vm._e(),_vm._v(" "),(_vm.withFooter)?_c('div',{staticClass:"simple-grid__foot"}):_vm._e()],2)])]),_vm._v(" "),(_vm.withFooter)?_c('div',{staticClass:"simple-grid__right-wrapper-footer"}):_vm._e()],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"simple-grid__cell simple-grid__cell--single simple-grid__add-row"},[_c('i',{staticClass:"iconoir-plus"})])
}]

export { render, staticRenderFns }